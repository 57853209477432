export const founder = [
  {
    name: "JCI Sen. GWO SOAGA",
    image:
      "https://res.cloudinary.com/duw4jtxls/image/upload/v1710663599/IMG-20240317-WA0010_mu7s63.jpg",
  },
  {
    name: "JCI Sen. Tope Olisa (Late)",
    image:
      "https://res.cloudinary.com/duw4jtxls/image/upload/v1710663520/IMG-20240317-WA0007_g2quvs.jpg",
  },
  {
    name: "JCI Sen. Bayo Oyelude",
    image:
      "https://res.cloudinary.com/duw4jtxls/image/upload/v1710663600/IMG-20240317-WA0009_ibn6sd.jpg",
  },
  {
    name: "JCI Sen. Mustapha Adekola, SAN",
    image:
      "https://res.cloudinary.com/duw4jtxls/image/upload/v1710663615/IMG-20240317-WA0008_mfdz9h.jpg",
  },
  {
    name: "JCI Sen. Abubakar Sanni",
    image:
      "https://res.cloudinary.com/duw4jtxls/image/upload/v1710663613/IMG-20240317-WA0011_zxttuc.jpg",
  },
];
